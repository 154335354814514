import React from 'react';
import './Empty.css';

const Empty = () => {
  return (
    <>
    <section className='error'>
      <h1>Error 404 !</h1>
    </section>
  </>
  )
}

export default Empty