const Data = [
    {
      image: "images/slider-1.jpeg",
    },
    {
      image: "images/slider-2.jpeg",
    },
    {
      image: "images/slider-3.jpeg",
    },
  ]
  export default Data