const GalleryData = [
    {
      img: "images/gallery1.jpeg",
      title: "Provision of farm tools",
    },
    {
      img: "images/gallery2.jpeg",
      title: "Food Donations",
    },
    {
      img: "images/gallery3.jpeg",
      title: "welfare packages",
    },
    {
      img: "images/gallery4.jpeg",
      title: "Welfare packages donations",
    },
    {
      img: "images/gallery5.jpeg",
      title: "Food Donations",
    },
    {
      img: "images/gallery6.jpeg",
      title: "Farm tools donations",
    },
    {
      img: "images/foodrive1.jpeg",
      title: "feed a child project",
    },
    {
      img: "images/foodrive2.jpeg",
      title: "feed a child project",
    },
    {
      img: "images/foodrive3.jpeg",
      title: "feed a child project",
    },
    {
      img: "images/foodrive4.jpeg",
      title: "feed a child project",
    },
    {
      img: "images/foodrive5.jpeg",
      title: "feed a child project",
    },
  ]
  
  export default GalleryData
  